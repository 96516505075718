// This component duplicates <Tile /> Tailwind styling from `apex-ui` with vanilla CSS.

export default function TilePreview({
  name,
  abbreviation,
  backgroundColor,
  textColor,
  isOfficialApp,
}: {
  name: string
  abbreviation: string
  backgroundColor: string
  textColor: string
  isOfficialApp: boolean
}) {
  return (
    <div
      style={{
        display: 'flex', // flex
        borderRadius: '0.375rem', // rounded-md (6px)
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', // shadow-sm
        height: 60,
      }}
    >
      <div
        style={{
          backgroundColor,
          display: 'flex', // flex
          width: '4rem', // w-16 (16 * 0.25rem = 4rem)
          flexShrink: 0, // shrink-0
          alignItems: 'center', // items-center
          justifyContent: 'center', // justify-center
          borderTopLeftRadius: '0.375rem', // rounded-l-md (6px, applies only to the left corners)
          borderBottomLeftRadius: '0.375rem', // rounded-l-md
          border: '1px solid #e5e7eb',
          fontSize: '0.875rem', // text-sm (14px)
          fontWeight: 500, // font-medium
          color: textColor === 'text-black' ? 'black' : 'white',
        }}
      >
        {abbreviation}
      </div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'space-between',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
          borderBottom: '1px solid #e5e7eb',
          borderRight: '1px solid #e5e7eb',
          borderTop: '1px solid #e5e7eb',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            fontSize: '0.875rem',
          }}
        >
          <div
            style={{
              fontWeight: 500,
              color: '#111827', // text-gray-900
              cursor: 'pointer',
              marginTop: isOfficialApp ? 14 : '0',
            }}
          >
            {name}
            {isOfficialApp && (
              <p
                style={{
                  marginTop: '0.25rem', // mt-1
                }}
              >
                <span
                  style={{
                    display: 'inline-flex',
                    borderRadius: '0.375rem', // rounded-md
                    backgroundColor: '#F3F4F6', // bg-gray-100
                    paddingLeft: '0.375rem', // px-1.5 (1.5 * 0.25rem = 0.375rem)
                    paddingRight: '0.375rem',
                    paddingTop: '0.125rem', // py-0.5 (0.5 * 0.25rem = 0.125rem)
                    paddingBottom: '0.125rem',
                    fontSize: '0.75rem', // text-xs (12px)
                    fontWeight: 500, // font-medium
                    color: '#4B5563', // text-gray-600
                  }}
                >
                  <img
                    src="/apex-logo.png"
                    height={12}
                  />
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
