import React, { useEffect } from 'react';

import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { Button, Col, Row, Form } from 'react-bootstrap';
import * as yup from 'yup';

import { translate } from '@apex/react-toolkit/lib';
import IApplication, { IApplicationApiErrors } from 'types/application/IApplication';
import FormFeedback from 'common/FormFeedback';

const UpdatePreviewValues: React.FC<{
  onFormValuesChange: (values: IApplication) => void;
}> = ({ onFormValuesChange }) => {
  const { values: formikValues } = useFormikContext();

  useEffect(() => {
    onFormValuesChange(formikValues as IApplication);
  }, [formikValues, onFormValuesChange]);

  return null;
};

const AppDetailsForm: React.FC<{
  initialValues: IApplication
  onSubmit: (values: IApplication, formikHelpers: FormikHelpers<IApplication>) => void;
  disabled?: boolean;
  apiErrors: IApplicationApiErrors;
  onValuesUpdated: (values: IApplication) => void;
}> = ({
  initialValues,
  onSubmit,
  disabled,
  onValuesUpdated,
  apiErrors,
}) => {
    const schema = yup.object({
      name: yup.string().required(translate('nameRequired')),
      description: yup.string().nullable(),
    });

    return (
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(formInput, formikHelpers) => {
          onSubmit(formInput, formikHelpers);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          handleReset,
          values,
          errors,
          touched,
          dirty,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <UpdatePreviewValues
              onFormValuesChange={onValuesUpdated}
            />
            <Row className="mb-4">
              <Col>
                <Form.Group>
                  <Form.Label>{translate('name')}</Form.Label>
                  <Form.Control
                    value={values.name}
                    type="text"
                    name="name"
                    placeholder={translate('name')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    readOnly
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="name"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Form.Group>
                  <Form.Label>{translate('description')}</Form.Label>
                  <Form.Control
                    value={values.description}
                    type="text"
                    name="description"
                    placeholder={translate('description')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="description"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBackgroundColor">
              <Form.Label>{translate('backgroundColor')}</Form.Label>
              <Form.Control
                required
                name="background_color"
                type="color"
                placeholder={translate('backgroundColor')}
                value={values.background_color}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disabled}
              />
              <FormFeedback
                touched={touched}
                errors={errors}
                apiErrors={apiErrors}
                fieldName="background_color"
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{translate('abbreviation')}</Form.Label>
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="abbreviation"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Form.Group controlId="formTextColor" className="mb-3">
                  <Form.Label>{translate('textColor')}</Form.Label>
                  <Form.Select
                    required
                    name="text_color"
                    value={values.text_color}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  >
                    <option value="text-black">
                      {translate('black')}
                    </option>
                    <option value="text-white">
                      {translate('white')}
                    </option>
                  </Form.Select>
                  <FormFeedback
                    touched={touched}
                    errors={errors}
                    apiErrors={apiErrors}
                    fieldName="text_color"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Form.Group controlId="formIsOfficialApp" className="mb-3">
                  <Form.Check
                    checked={values.is_official_app}
                    type="switch"
                    name="is_official_app"
                    label={translate('isOfficialApp')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disabled}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-end">
                <Button
                  onClick={handleReset}
                  variant="secondary"
                  type="button"
                  disabled={disabled || !dirty}
                  className="me-1"
                >
                  {translate('cancel')}
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={disabled || !dirty}
                >
                  {translate('save')}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  };

export default AppDetailsForm;
