import React from 'react';
import { translate } from '@apex/react-toolkit/lib';
import IApplication from 'types/application/IApplication';
import AppDetailsFormWithTilePreview from './AppDetailsFormWithTilePreview';
import { useEditApplicationMutation } from 'api/applicationSlice';
import useToast from 'hooks/useToast';

const Details: React.FC<{
  application: IApplication
}> = ({ application }) => {
  const [editApplication, { isLoading, error: apiErrors }] = useEditApplicationMutation();
  const toast = useToast();

  return (
    <AppDetailsFormWithTilePreview
      initialValues={application}
      disabled={isLoading}
      apiErrors={apiErrors}
      onSubmit={async (values) => {
        await editApplication(values);
        toast({
          bg: 'success',
          title: translate('success'),
          message: translate('updatedSuccessfully'),
          autohide: true,
        });
      }}
    />
  );
};

export default Details;
