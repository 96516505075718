import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import IApplication from 'types/application/IApplication';
import AppDetailsForm from './AppDetailsForm';
import { FormikHelpers } from 'formik';
import { IApplicationApiErrors } from 'types/application/IApplication';
import TilePreview from 'common/application/ApplicationFormWithPreview/TilePreview';

const AppDetailsFormWithTilePreview: React.FC<{
  disabled?: boolean;
  initialValues: IApplication;
  onSubmit: (values: IApplication, formikHelpers: FormikHelpers<IApplication>) => void;
  apiErrors: IApplicationApiErrors
}> = ({
  disabled,
  initialValues,
  onSubmit,
  apiErrors,
}) => {
    const [currentValues, setCurrentValues] = useState(initialValues);

    return (
      <Row>
        <Col md={6}>
          <AppDetailsForm
            onValuesUpdated={(values: IApplication) => {
              setCurrentValues(values);
            }}
            initialValues={initialValues}
            disabled={disabled}
            apiErrors={apiErrors}
            onSubmit={onSubmit}
          />
        </Col>
        <Col md={6}>
          <Row className='mb-2'>
            <Col className="d-flex" md={6}>
              <div>
                <h4>{translate('tilePreview')}</h4>
                <Row>
                  <Col>
                    <TilePreview
                      name={initialValues.name}
                      abbreviation={currentValues.abbreviation}
                      backgroundColor={currentValues.background_color}
                      textColor={currentValues.text_color}
                      isOfficialApp={currentValues.is_official_app}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

export default AppDetailsFormWithTilePreview;
